const RefreshIcon = ({ className }: { className?: string }): JSX.Element => {
  return (
    <svg
      className={className || 'w-3.5 h-3.5 fill-current'}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.44 0.780041C4.67196 0.470762 5.11072 0.408082 5.42 0.640041L7.98 2.56004L6.06 5.12004C5.82804 5.42932 5.38928 5.492 5.08 5.26004C4.77072 5.02808 4.70804 4.58932 4.94 4.28004L5.35586 3.72556C3.79678 4.37123 2.7 5.90755 2.7 7.70004C2.7 10.0749 4.62518 12 7 12C9.37483 12 11.3 10.0749 11.3 7.70004C11.3 6.49701 10.8069 5.41043 10.01 4.62919C9.7339 4.35856 9.7295 3.91536 10.0001 3.63929C10.2708 3.36322 10.714 3.35882 10.99 3.62946C12.0445 4.66315 12.7 6.10567 12.7 7.70004C12.7 10.8481 10.148 13.4 7 13.4C3.85198 13.4 1.3 10.8481 1.3 7.70004C1.3 5.15868 2.96317 3.00576 5.26043 2.27036L4.58 1.76004C4.27072 1.52808 4.20804 1.08932 4.44 0.780041Z'
      />
    </svg>
  );
};

export { RefreshIcon };
